import { Buffer } from "buffer";
import { defer, omit } from "lodash";
import { decode, encode } from "msgpack-lite";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  BsFillTrashFill,
  BsReceiptCutoff,
  BsXCircleFill,
} from "react-icons/bs";

type Item = {
  description: string;
  unitPriceString: string;
  unitPrice: number;
  quantityString: string;
  quantity: number;
  assignedTo: string;
};

function modifyItemAt(
  i: number,
  items: Item[],
  replacement: Partial<Item>
): Item[] {
  return [
    ...items.slice(0, i),
    {
      ...items[i],
      ...replacement,
    },
    ...items.slice(i + 1),
  ];
}

enum ButtonStyle {
  Regular = "bg-slate-600 text-slate-100",
  Danger = "bg-red-500 text-white",
}

const Button: React.FC<
  {
    color: ButtonStyle;
    thin?: boolean;
  } & React.ButtonHTMLAttributes<HTMLButtonElement>
> = (props) => {
  return (
    <button
      {...omit(props, "color", "thin")}
      className={
        props.thin
          ? `text-xs py-1 px-2 ${props.color} rounded-md`
          : `py-1 px-2 ${props.color} rounded-md`
      }
    >
      {props.children}
    </button>
  );
};

const Input: React.FC<
  {
    prefix?: string;
    inputClassName?: string;
    clearable?: boolean;
  } & React.InputHTMLAttributes<HTMLInputElement>
> = (props) => {
  const iref = useRef<HTMLInputElement>(null);
  return (
    <div className="py-1 px-2 bg-white items-center rounded-md flex">
      {props.prefix && <div className="mr-1">{props.prefix}</div>}
      <input
        {...omit(props, "className", "clearable")}
        className={(props.inputClassName || "") + " " + "w-full block p-0 m-0"}
        ref={iref}
      />
      {props.clearable && (
        <BsXCircleFill
          color="#475569"
          style={{ cursor: "pointer", margin: "2px" }}
          size={11}
          onClick={() => {
            if (iref.current) {
              iref.current.value = "";
              defer(() => {
                iref.current!.focus();
              });
            }
          }}
        />
      )}
    </div>
  );
};

class Node<T> {
  public readonly value: T;
  public next: Node<T>;
  constructor(value: T, next?: Node<T>) {
    this.value = value;
    if (!next) {
      this.next = this;
    } else {
      this.next = next;
    }
  }

  insert(value: T) {
    const n = new Node(value, this.next);
    this.next = n;
  }
}

function arrayToLinkedList<T>(arr: T[]): Node<T> {
  if (arr.length === 0) {
    throw new Error("arrayToLinkedList: zero length array");
  }
  let current: T | undefined;
  let ll: Node<T> = new Node<T>(arr.shift()!);
  while ((current = arr.shift())) {
    ll.insert(current);
  }
  return ll;
}

const itemSumReducer = (mem: number, item: Item): number =>
  mem + item.quantity * item.unitPrice;

const itemSum = (items: Item[]): number => {
  return items.reduce(itemSumReducer, 0);
};

const hashState = (():
  | { parties: string[]; items: Item[]; total: number }
  | undefined => {
  const hashContents = global.location.hash.replaceAll(/^#/g, "");
  if (hashContents.length) {
    const buf = Buffer.from(hashContents, "base64");
    const s = decode(buf);
    if ("v" in s && s.v === 1) {
      return {
        total: s.t,
        parties: s.p,
        items: s.i.map(
          (i: any): Item => ({
            description: i.d,
            unitPriceString: `${i.up}`,
            unitPrice: i.up,
            quantityString: `${i.q}`,
            quantity: i.q,
            assignedTo: i.a,
          })
        ),
      };
    }
  }
})();

const initialItems = (): Item[] => {
  return [
    {
      description: "New Item #1",
      unitPriceString: "0",
      unitPrice: 0,
      quantityString: "0",
      quantity: 0,
      assignedTo: "",
    },
  ];
};

function App() {
  // use "edit count" to understand when a user truly edits to start updating
  // the hash for sharing
  const [editCount, setEditCount] = useState(0);
  const incEditCount = () => {
    setEditCount((n) => n + 1);
  };
  const [deleteMode, setDeleteMode] = useState(false);
  const [total, setTotal] = useState<{ text: string; value: number }>(() => {
    const value = hashState?.total ?? 0;
    return {
      value,
      text: `${value}`,
    };
  });
  const [parties, setParties] = useState<string[]>(() => {
    return hashState?.parties ?? [];
  });
  const [items, setItems] = useState<Item[]>(() => {
    return hashState?.items ?? initialItems();
  });
  const [disableTotalFix, setDisableTotalFix] = useState(false);
  useEffect(() => {
    if (disableTotalFix) {
      return;
    }
    const itemsTotal = itemSum(items);
    if (itemsTotal > total.value) {
      setTotal({
        text: `${round2(itemsTotal)}`,
        value: round2(itemsTotal),
      });
    }
  }, [items, total, setTotal, disableTotalFix]);
  useEffect(() => {
    // do not put just the empty stuff in the hash, wait for the user to
    // actually edit before generating the hash
    if (editCount === 0) {
      return;
    }
    const buf = encode({
      v: 1,
      t: total.value,
      p: parties,
      i: items.map((i) => ({
        d: i.description,
        up: i.unitPrice,
        q: i.quantity,
        a: i.assignedTo,
      })),
    });
    const s = Buffer.from(buf).toString("base64");
    window.history.replaceState({}, "", `/#${s}`);
  }, [items, total, parties, total, editCount]);
  const createNewParty = useCallback(
    (itemIdx: number) => {
      const newParty = prompt("What should we call this assignee?");
      if (!newParty) {
        return;
      }
      incEditCount();
      setParties((currentParties) => [...currentParties, newParty]);
      if (parties.length === 0) {
        setItems((currentItems) => {
          return currentItems.map((i) => ({ ...i, assignedTo: newParty }));
        });
      } else {
        setItems((currentItems) => {
          return modifyItemAt(itemIdx, currentItems, {
            assignedTo: newParty,
          });
        });
      }
    },
    [parties, setItems]
  );
  const focusTrackNextOnKeyDown =
    (focustrackID: string) => (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        e.preventDefault();
        const results = document.querySelectorAll<HTMLElement>(
          `[data-focustrack="${focustrackID}"]`
        );
        let ll = arrayToLinkedList<HTMLElement>([...results].reverse());
        for (let i = 0; i < results.length; i++) {
          if (ll.value.isEqualNode(e.currentTarget)) {
            ll.next.value.focus();
            break;
          }
          ll = ll.next;
        }
      }
    };
  return (
    <div className="App prose" style={{ padding: ".5em" }}>
      <h3 className="prose-h3 flex items-center gap-2">
        <BsReceiptCutoff />
        fairsplit
      </h3>
      <p>split a bill, the fair way.</p>
      <p>
        Enter in the items of the bill to get a sub-total. Assign the items to
        something/someone. Use the total to set the final bill amount that
        includes tax/tip/shipping/etc..
      </p>
      <p>
        The result will be the amount each assignee will be responsible for with
        the extra fees weighted towards whoever is responsible for most of the
        bill.
      </p>
      <div className="rounded-md pt-4 pb-1 px-2 bg-slate-100">
        <div>
          {items.map((item, i) => (
            <div key={`item-${i}`} className="bg-slate-200 mb-3 p-1 rounded-md">
              <div className="flex gap-1">
                <div className="basis-7/12">
                  <Input
                    type="text"
                    data-focustrack={`item-${i}`}
                    data-t={`item-${i}-desc`}
                    value={item.description}
                    clearable
                    onKeyDown={focusTrackNextOnKeyDown(`item-${i}`)}
                    onChange={(e) => {
                      incEditCount();
                      setItems((currentItems) => {
                        return modifyItemAt(i, currentItems, {
                          description: e.target.value,
                        });
                      });
                    }}
                  />
                </div>

                <div className="basis-3/12">
                  <Input
                    type="number"
                    data-focustrack={`item-${i}`}
                    data-t={`item-${i}-unitprice`}
                    prefix="$"
                    inputMode="decimal"
                    value={item.unitPriceString}
                    onKeyDown={focusTrackNextOnKeyDown(`item-${i}`)}
                    onChange={(e) => {
                      const strval = (e.currentTarget || e.target).value;
                      const value = parseFloat(strval);
                      setItems((currentItems) => {
                        if (!isNaN(value) && strval.length > 0) {
                          incEditCount();
                          return modifyItemAt(i, currentItems, {
                            unitPrice: value,
                            unitPriceString: strval,
                          });
                        }
                        return modifyItemAt(i, currentItems, {
                          unitPriceString: strval,
                        });
                      });
                    }}
                  />
                </div>
                <div className="basis-2/12">
                  <Input
                    type="number"
                    data-focustrack={`item-${i}`}
                    data-t={`item-${i}-quantity`}
                    inputMode="decimal"
                    prefix="×"
                    value={item.quantityString}
                    onKeyDown={focusTrackNextOnKeyDown(`item-${i}`)}
                    onChange={(e) => {
                      const strval = (e.currentTarget || e.target).value;
                      const value = parseFloat(strval);
                      setItems((currentItems) => {
                        if (!isNaN(value) && strval.length > 0) {
                          incEditCount();
                          return modifyItemAt(i, currentItems, {
                            quantity: value,
                            quantityString: strval,
                          });
                        }
                        return modifyItemAt(i, currentItems, {
                          quantityString: strval,
                        });
                      });
                    }}
                  />
                </div>
              </div>
              <div className="flex justify-between items-center">
                {deleteMode ? (
                  <div>
                    <Button
                      thin
                      color={ButtonStyle.Danger}
                      onClick={() => {
                        incEditCount();
                        setItems((currentItems) => [
                          ...currentItems.slice(0, i),
                          ...currentItems.slice(i + 1),
                        ]);
                      }}
                    >
                      <BsFillTrashFill
                        style={{ display: "inline-block", marginBottom: "1px" }}
                      />
                      &nbsp;Delete
                    </Button>
                  </div>
                ) : (
                  <div className="pr-1 py-1 flex gap-2 text-sm">
                    <div>Assigned To:</div>
                    <div>
                      <select
                        className="rounded-sm"
                        value={item.assignedTo}
                        onClick={(e) => {
                          e.preventDefault();
                          if (parties.length === 0) {
                            (e.target as HTMLSelectElement).blur();
                            createNewParty(i);
                          }
                        }}
                        onChange={(e) => {
                          const to = e.target.value;
                          if (
                            to === "2a47542b-6f1b-4032-9d3a-e43446fa02c6add-new"
                          ) {
                            e.preventDefault();
                            createNewParty(i);
                          } else {
                            incEditCount();
                            setItems((currentItems) => {
                              return modifyItemAt(i, currentItems, {
                                assignedTo: to,
                              });
                            });
                          }
                        }}
                      >
                        {parties.map((p, i) => (
                          <option key={`party-${i}`} value={`${p}`}>
                            {p}
                          </option>
                        ))}
                        <option
                          value={"2a47542b-6f1b-4032-9d3a-e43446fa02c6add-new"}
                        >
                          Add New...
                        </option>
                      </select>
                    </div>
                  </div>
                )}
                <div className="text-right text-xs text-slate-900 pt-2 pb-1 pl-1">
                  Item Total:&nbsp;
                  {formatCurrency(round2(item.quantity * item.unitPrice))}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="flex gap-2">
          <div>
            <Button
              color={ButtonStyle.Regular}
              onClick={() => {
                incEditCount();
                setItems((currentItems) => [
                  ...currentItems,
                  {
                    description: `New item #${currentItems.length + 1}`,
                    unitPriceString: "0",
                    unitPrice: 0,
                    quantityString: "1",
                    quantity: 1,
                    assignedTo: "",
                  },
                ]);
              }}
            >
              Add Item
            </Button>
          </div>
          <div>
            <Button
              color={deleteMode ? ButtonStyle.Danger : ButtonStyle.Regular}
              onClick={() => {
                setDeleteMode((v) => !v);
              }}
            >
              <BsFillTrashFill style={{ display: "inline-block" }} />
            </Button>
          </div>
          {deleteMode && (
            <div>
              <Button
                color={ButtonStyle.Danger}
                onClick={() => {
                  setParties([]);
                  setItems(initialItems());
                  setEditCount(0);
                  setTotal({ text: "0", value: 0 });
                  setDeleteMode(false);
                  global.history.replaceState(null, "", "/");
                }}
              >
                Reset
              </Button>
            </div>
          )}
          <div className="flex gap-2 mb-2 items-center w-36">
            <div>
              <strong className="text-slate-900">Total:</strong>
            </div>
            <div>
              <Input
                type="number"
                prefix="$"
                inputMode="decimal"
                value={total.text}
                onFocus={() => {
                  setDisableTotalFix(true);
                }}
                onBlur={() => {
                  setDisableTotalFix(false);
                }}
                onChange={(e) => {
                  const strval = (e.currentTarget || e.target).value;
                  const value = parseFloat(strval);
                  if (!isNaN(value) && strval.length > 0) {
                    incEditCount();
                    setTotal({ text: strval, value });
                  } else {
                    setTotal((currentTotal) => ({
                      ...currentTotal,
                      text: strval,
                    }));
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {parties.length > 0 && (
        <div className="rounded-md py-1 px-2 bg-slate-100 mt-3">
          <div id="party-totals">
            {parties.map((p, i) => {
              const isLast = i === parties.length - 1;
              const partyItems = items.filter((i) => i.assignedTo === p);
              const subTotal = itemSum(items);
              const averagePartyTotal = subTotal / parties.length;
              const pItemsTotal = itemSum(partyItems);
              const portionMultiplier = pItemsTotal / averagePartyTotal;
              const tipsAndFees = total.value - subTotal;
              const averageTipsAndFeesPerParty = tipsAndFees / parties.length;
              const partyTipsAndFees =
                averageTipsAndFeesPerParty * portionMultiplier;
              const partyTotal = partyTipsAndFees + pItemsTotal;
              return (
                <div key={p}>
                  <div>
                    <strong>Assignee Total: </strong> {p}
                  </div>
                  <div>Sub total = {formatCurrency(pItemsTotal)}</div>
                  <div>
                    Tips/Fees ={" "}
                    {formatCurrency(
                      isNaN(partyTipsAndFees) ? 0 : partyTipsAndFees
                    )}
                  </div>
                  <div>
                    Total = {formatCurrency(isNaN(partyTotal) ? 0 : partyTotal)}
                  </div>
                  {!isLast && (
                    <div className="not-prose">
                      <hr className="my-2" />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

function formatCurrency(n: number): string {
  const f = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "usd",
  });
  return f.format(n);
}

function round2(n: number): number {
  return Math.round(n * 100) / 100;
}

export default App;
